import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import {
  CloseIconType,
  FullScreenDialogTitle,
} from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { type WorkplaceQuizResultLogSchema } from "@src/appV2/WorkplaceQuiz/types";
import {
  WorkplaceRules,
  type WorkplaceRulesProps,
} from "@src/appV2/WorkplaceQuiz/WorkplaceRules/WorkplaceRules";

interface QuizRulesDialogProps {
  modalState: UseModalState;
  workplaceName: string;
  facilityNotes: WorkplaceRulesProps["facilityNotes"];
  latestQuizResultLog?: WorkplaceQuizResultLogSchema;
  onStartQuiz: () => void;
  onRulesClosed: () => void;
}

export function RulesDialog(props: QuizRulesDialogProps) {
  const {
    modalState,
    workplaceName,
    facilityNotes,
    latestQuizResultLog,
    onStartQuiz,
    onRulesClosed,
  } = props;
  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle
        closeIconType={CloseIconType.CLOSE}
        onClose={() => {
          onRulesClosed();
        }}
      >
        {workplaceName}
      </FullScreenDialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <WorkplaceRules
          facilityNotes={facilityNotes}
          latestQuizResultLog={latestQuizResultLog}
          onStartQuiz={onStartQuiz}
        />
      </DialogContent>
    </FullScreenDialog>
  );
}
