import { formatTime } from "@clipboard-health/date-time";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { format, formatDuration } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface QuizPassedDialogWithShiftProps {
  onCancel: () => void;
  onBookShift: () => void;
  modalState: UseModalState;
  workplace: Pick<Facility, "name" | "tmz">;
  shift: Pick<Shift, "start" | "end" | "time">;
}

/**
 * Returns a formatted day and date string without year e.g. "Tuesday, March 26"
 */
function getDayAndDateString(startTime: string, timeZone: string) {
  const startTimeLocal = utcToZonedTime(startTime, timeZone);
  return format(startTimeLocal, "EEEE, MMMM d");
}

/**
 * Returns shift duration in hours and minutes e.g. "6 hours 30 minutes"
 */
function getHumanReadableShiftDuration(shiftDuration: number): string {
  const hours = Math.floor(shiftDuration);
  const minutes = Math.round((shiftDuration - hours) * 60);
  const duration = { hours, minutes };
  return formatDuration(duration, { format: ["hours", "minutes"], delimiter: " " });
}

export function QuizPassedDialogWithShift(props: QuizPassedDialogWithShiftProps) {
  const {
    onCancel,
    onBookShift,
    modalState: { modalIsOpen, closeModal },
    shift,
    workplace,
  } = props;

  return (
    <Dialog open={modalIsOpen} onClose={closeModal}>
      <DialogTitle>You passed the quiz!</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>
          You&apos;re now able to book instantly at this facility. You won&apos;t need to take this
          quiz again unless {workplace.name} adds more information.
        </DialogContentText>
        {isDefined(workplace.tmz) && isDefined(shift.time) ? (
          <>
            <br />
            <DialogContentText>
              By booking this shift, you&apos;re committing to working this shift.
            </DialogContentText>
            <br />
            <DialogContentText>
              <b>{getDayAndDateString(shift.start, workplace.tmz)}</b>
            </DialogContentText>
            <DialogContentText>
              <b>
                {formatTime(shift.start, { timeZone: workplace.tmz })} -{" "}
                {formatTime(shift.end, { timeZone: workplace.tmz })} (
                {getHumanReadableShiftDuration(shift.time)})
              </b>
            </DialogContentText>
            <DialogContentText>
              <b>{workplace.name}</b>
            </DialogContentText>
          </>
        ) : null}
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={<Button onClick={onCancel}>Cancel</Button>}
        rightAction={<Button onClick={onBookShift}>Book Shift</Button>}
      />
    </Dialog>
  );
}
