import { type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { LegacyOneButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";

export interface QuizPassedDialogProps {
  onContinue: () => void;
  modalState: UseModalState;
  workplace: Pick<Facility, "name">;
}

export function QuizPassedDialog(props: QuizPassedDialogProps) {
  const {
    onContinue,
    modalState: { modalIsOpen, closeModal },
    workplace,
  } = props;

  return (
    <Dialog open={modalIsOpen} onClose={closeModal}>
      <DialogTitle>You passed the quiz!</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>
          You&apos;re now able to book instantly at this facility. You won&apos;t need to take this
          quiz again unless {workplace.name} adds more information.
        </DialogContentText>
      </DialogContent>
      <LegacyOneButtonDialogActions
        action={<Button onClick={onContinue}>VIEW OPEN SHIFTS</Button>}
      />
    </Dialog>
  );
}
