import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import {
  CloseIconType,
  FullScreenDialogTitle,
} from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { QuizForm } from "@src/appV2/WorkplaceQuiz/QuizForm/QuizForm";
import { type WorkplaceQuizData } from "@src/appV2/WorkplaceQuiz/types";

interface QuizFormDialogProps {
  modalState: UseModalState;
  workplaceName: string;
  quiz: WorkplaceQuizData;
  onQuizFailed: () => void;
  onQuizPassed: () => void;
  onQuizClosed: () => void;
  onQuizComplete: () => Promise<void>;
}

export function QuizFormDialog(props: QuizFormDialogProps) {
  const {
    modalState,
    workplaceName,
    quiz,
    onQuizFailed,
    onQuizPassed,
    onQuizClosed,
    onQuizComplete,
  } = props;
  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle
        closeIconType={CloseIconType.CLOSE}
        onClose={() => {
          onQuizClosed();
        }}
      >
        {workplaceName}
      </FullScreenDialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <QuizForm
          quiz={quiz}
          onQuizComplete={onQuizComplete}
          onQuizFailed={onQuizFailed}
          onCancelRetry={() => {
            modalState.closeModal();
          }}
          onQuizPassed={() => {
            modalState.closeModal();
            onQuizPassed();
          }}
        />
      </DialogContent>
    </FullScreenDialog>
  );
}
